*, :after, :before {
  box-sizing: border-box;
}

$green: #009f5b;
$green-clear: #07b86c;
$light-blue: #3bb9ff;
$blue: #0a86c4;
$dark-blue: #00485c;
$dark-blue-2: #004a63;
$dark-blue-3: #005f7a;
$dark-blue-disabled: #327291;
$red: #a93232;
$light-red: #b66a6a;
$yellow: #d9c484;
$grey: #dbdbdb;
$box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);

html, body {width: 100%; height: 100%; background-color: $dark-blue-2;}

.small-text {font-size: 0.8rem;}
.w-100 {width: 100%;}
.h-100 {height: 100%;}
.bg-green {background-color: $green;}
.d-none {display: none;}
.d-block {display: block!important}
.d-flex {display: flex;}
.justify-content-center {justify-content: center!important;}
.align-items-center {align-items: center;}
.flex-column {flex-direction: column;}
.text-center {text-align: center;}
.mb-2 {margin-bottom: 1rem;}
.z-index-1 {z-index: 1;}
.z-index-2 {z-index: 2;}
.position-relative {position: relative;}
.bold {font-weight: bold;}
.mt-1 {margin-top: 0.5rem;}
.mb-1 {margin-bottom: 0.5rem;}
.mb-2 {margin-bottom: 1rem;}
.ml-1 {margin-left: 0.5rem;}
.ml-2 {margin-left: 1rem;}
.mt-2 {margin-top: 1rem;}
.cursor-pointer {cursor: pointer;}
.shadow-background {background-color: #00000026;}
.rotate-90 {transform: rotate(90deg);}

body {display: flex; align-items: center; justify-content: center;}
#root {max-width: 600px; width: 100%; margin: auto; height: 100%; max-height: 800px;}

.App {
  text-align: center;
  background-color: $dark-blue-2;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  > div {
    height: 100%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#logout {position: absolute; top: 10px; right: 10px; cursor: pointer;}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

header {
  width: 100%;
  top: 8px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: $yellow;

  img {
    width: 20px;
    margin-left: 10px;
  }
}

main {
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 40px 10px 52px 10px;
  height: 100%;
  transition: all 0.3s;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

table {
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  margin-bottom: 0.3rem;
  display: inline-block;
  text-align: left;
}

form {
  width: 100%;
}

input, button {
  background-color: white;
  border: 1px solid rgb(209, 213, 219);
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: 100%;
  transition: all 0.2s;
  color: black;

  &:focus {
    outline: none;
  }

  &.is-invalid {
    box-shadow: 0px 0px 4px 1px #a9323257;
    margin-bottom: 0.5rem;
  }
}

.btn-blue {
  background-color: $blue; border: none; color: white;
  &.disabled {
    background-color: $dark-blue-disabled;
  }
}

.btn-red {
  background-color: $light-red; border: none; color: white;
  &.disabled {
    background-color: $light-red;
  }
}

.btn-confirm {width: 50px; margin: 10px;}

.invalid-form {
  font-size: 0.9rem;
  color: $light-blue;
  transition: all 0.3s;
  opacity: 0;
  height: 0;
  margin: 0;

  &.invalid-form--visible {
    opacity: 1;
    height: 20px;
    margin-bottom: 0.5rem;
  }
}

#login {min-height: 250px;}

.lds-ripple {
  position: absolute;
  bottom: 70px;
  margin: auto;
  left: 0;
  right: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 999;

  div {
    position: absolute;
    border: 4px solid #ffffff9e;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 24px;
    left: 24px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    opacity: 0;
  }
}

#menu {
  position: absolute;
  display: block;
  height: 52px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin-bottom: 0;
  color: $green;
  box-shadow: 0px -1px 14px -2px rgba(0,0,0,0.20);
  background-color: $blue;
  z-index: 100;

  #tabs {
    display: flex;
    justify-content: space-between;

    a {
      padding: 8px;
      color: $dark-blue;
      text-decoration: none;
      width: 100%;
      font-size: 0.9rem;

      &.selected {
        box-shadow: 0px 2px 14px -2px rgba(0,0,0,0.20);
        color: white;
      }

      svg {display: inline-block;}
      span {display: block; font-size: 0.8rem;}
    }
  }
}

#wordForm {margin-top: 10px;}

#list-words-admin {overflow: hidden; flex: 1; display: flex; flex-direction: column; max-height: 700px; margin-top: 5px;}

.list {
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {display: none;}

  .title-rules {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .word {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 5px;
    font-size: 0.9rem;
    transition: all 0.3s;

    .thai {
      font-size: 1.25em;
    }

    a {color: white;}

    .actions {
      svg {cursor: pointer; margin-left: 10px;}
    }

    &:nth-child(even){background: #00000026;}

    .rule {
      text-align: center;
      padding: 10px 20px;

      &:nth-child(even){background: #00000026;}
    }
  }
}

.search {
  border-bottom: 1px solid #ffffff5e;
}

.table-rules {
  font-size: 1rem;

 .rule {
   width: 20%;

   b {
     font-size: 18px;
     font-weight: normal;
   }
 }

  &.even {
    tr {
      &:nth-child(even){background: #00000026;}
    }
  }

  &.odd {
    tr {
      &:nth-child(odd){background: #00000026;}
    }
  }
}

#success-form {
  position: absolute;
  z-index: 999;
  bottom: 57px;
  margin: auto;
  left: 0;
  right: 0;
  display: inline-block;
  width: 30px;
  height: 30px;
  color: $light-blue;
  transition: all 0.5s;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  transition: all 0.5s;
  padding: 20px;

  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: auto;
    position: relative;
    width: auto;
    pointer-events: none;
    max-width: 500px;
    flex-direction: column;

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      outline: 0;
      border-radius: 21px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding: 20px 20px 20px 20px;
      border: transparent;
    }
  }

  .surrender-modal {
    color: $blue;
    text-transform: uppercase;
    font-family: Poppins-bold,sans-serif;
    font-size: 10px;
    cursor: pointer;
    pointer-events: auto;
    margin-top: 1.5em;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #242424;
  transition: all 0.5s;

  &.show {
    opacity: .5;
  }
}

.fade {
  transition: opacity .15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.page-exit-done {
  display: none;
}

.animate {
  transition: all 0.2s;
  z-index: 1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(0.9);

  &.show {
    z-index: 10;
    opacity: 1;
    transform: scale(1);
  }
}

.animate-left-to-right {
  transition: all 0.2s;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 110%;
  transform: scale(1) translateX(100%);
  z-index: 1;
  width: 0;

  &.show {
    left: 0;
    z-index: 300;
    opacity: 1;
    width: 100%;
    background-color: $dark-blue;
    transform: translateX(0%);
  }
}

.animate-bottom-to-top {
  transition: all 0.2s;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(1) translateY(100%);
  z-index: 1;
  width: 100%;

  &.show {
    left: 0;
    top: 0;
    z-index: 200;
    opacity: 1;
    width: 100%;
    background-color: $dark-blue;
    transform: translateY(0%);
  }
}

.back-to-page {
  position: absolute;
  top: 18px;
  left: 18px;
  cursor: pointer;

  a {color: white; font-size: 1.1rem;}
}

.card-word {
  border: 1px solid $grey;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  text-align: left;

  .english {font-size: 0.9rem;}
  .thai {font-size: 1.4rem;}
  .phonetics {font-weight: bold;}
}

#letters-phonetics {
  margin-bottom: 5px;
  span {margin: 5px 1px;}
}

.categories {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-template-rows: auto;
  justify-content: space-around;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {display: none;}
}

.category {
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  opacity: 0.9;
  transition: all 0.5s;
  box-shadow: $box-shadow;

  &:hover {
    transform: scale(1);
    opacity: 1;
  }

  svg {
    font-size: 2rem;
    padding: 10px;
    box-sizing: content-box;
  }

  .english {font-size: 1.2rem; font-weight: bold;}
  .phonetic {font-size: 0.9rem;}
}

.title-homepage {font-size: 1rem; font-weight: bold; margin-bottom: 5px;}

.btn-copy-to-clipboard {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.copied-to-clipboard {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  background-color: #00000059;
  border-radius: 10px;
  padding: 5px;
  transition: all 0.4s;
  width: 60%;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  font-size: 0.9rem;
  cursor: pointer;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.install-pwa {
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  width: 60%;
  margin: auto;
  font-weight: bold;
  border: none;
  z-index: 999;

  button {
    width: 100%;
    height: 100%;
    background-color: #0a86c4;
    color: white;
    margin-bottom: 0;
    cursor: pointer;
    border: none;
    box-shadow: $box-shadow;
    position: relative;
  }

  .close-btn {
    position: absolute;
    top: 2px;
    right: 10px;
    color: white;
    cursor: pointer;
  }
}